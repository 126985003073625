import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { addEntity, setAllEntities, updateEntity, withEntities } from "@ngrx/signals/entities";
import { withSsmartButtonsApi } from "./smart-buttons-api.feature";
import { computed } from "@angular/core";
import { ApiSmartButtonsIdPatch$Params } from "../../../../../api/fn/operations/api-smart-buttons-id-patch";
import { concatMap, from, tap } from "rxjs";
import { SmartButtonData } from "../../popups/models/classes/helpers/smart-button-data";
import { SmartButtonsResponse } from "../../../../../api/models/smart-buttons-response";
import { SmartButtonsCondition } from "../../../../../api/models/smart-buttons-condition";

type TSmartButtonsState = {
    isLoaded: boolean,
    currentConditionId: string,
    smartButtonData: SmartButtonData | undefined
};

export const initialSmartButtonsState: TSmartButtonsState = {
    isLoaded: false,
    currentConditionId: '',
    smartButtonData: undefined
};

export const SmartButtonsStore = signalStore(
    { providedIn: "root", protectedState: false },
    withState(initialSmartButtonsState),
    withEntities<SmartButtonsResponse>(),
    withSsmartButtonsApi(),
    withMethods((store: any) => ({
        addSmartButton: (button: SmartButtonsResponse) => {
            patchState(store, addEntity(button));
        },
        addConditionSmartButton: (id: string, condition: any) => {
            patchState(store, updateEntity({
                id: id,
                changes: { ...condition }
            }));
        },
        updateCollection: (updateButton: SmartButtonsResponse[]) => {
            patchState(store, setAllEntities(updateButton));
        },
        setCurrentConditionId: (id: string) => {
            patchState(store, { currentConditionId: id })
        },
        createButtons: (buttons: ApiSmartButtonsIdPatch$Params[]) => {
            from(buttons)
                .pipe(
                    concatMap((button) =>
                        store.createSmartButton(button.body).pipe(
                            tap({
                                next: () => console.log(`Успешно обработано: ${button.id}`),
                                error: (error) => console.error(`Ошибка обработки ${button.id}:`, error),
                            })
                        )
                    )
                )
                .subscribe({
                    complete: () => console.log('Все запросы обработаны'),
                });
        },
        updateButtons: (buttons: ApiSmartButtonsIdPatch$Params[]) => {
            from(buttons)
                .pipe(
                    concatMap((button) =>
                        store.patchUpdateSmartButton(button).pipe(
                            tap({
                                next: () => console.log(`Успешно обработано: ${button.id}`),
                                error: (error) => console.error(`Ошибка обработки ${button.id}:`, error),
                            })
                        )
                    )
                )
                .subscribe({
                    complete: () => console.log('Все запросы обработаны'),
                });
        },
        removeSmartButtonById: (id: string) => {
            store.deleteSmartButton(id);
        },
        updateCondition: (id: string, smartButtonsCondition: SmartButtonsCondition[]) => {
            let patchCondition: ApiSmartButtonsIdPatch$Params = {
                id: id,
                body: {
                    condition: smartButtonsCondition
                }
            }
            store.patchIdSmartButton(patchCondition);
        }
    })),
    withComputed((store) => ({
        getSmartButtonConditions: computed(() => {
            return store.entityMap()[store.currentConditionId()]?.condition || [];
        }),
        getSmartButtonParentCondition: computed(() => {
            return store.entityMap()[store.currentConditionId()] || [];
        })
    })),
)
